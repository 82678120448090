import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import Constants from './Constants';

const Pil = styled.div`
  font-size: 0.75em;
  white-space: nowrap;
`;

const Dot = styled.div`
  border-radius: 0.3em;
  width: 0.6em;
  height: 0.6em;
  background-color: grey;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.4em 0.15em 0.4em;
`;

const GreenDot = styled(Dot)`
  background-color: ${Constants.GREEN};
`;

const BlueDot = styled(Dot)`
  background-color: ${Constants.BLUE};
`;

const StatusPil = ({ children, status }) => {
  switch (status?.status) {
    case 'inactive':
      return (
        <Pil>
          Idle {children}
          <BlueDot />
        </Pil>
      );
    case 'success':
      return (
        <Pil>
          Active {children}
          <GreenDot />
        </Pil>
      );
    default:
      return (
        <Pil>
          Offline {children}
          <Dot />
        </Pil>
      );
  }
};

function Status({ status }) {
  const [lastSeen, setLastSeen] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!status) {
        return;
      }

      const isoDate = DateTime.fromISO(status.statusSince);
      const date = isoDate.isValid
        ? isoDate
        : DateTime.fromSQL(status.statusSince);
      const diff = date.diffNow().milliseconds;
      setLastSeen(humanizeDuration(diff, { largest: 1, round: true }));
    }, 1000);

    return () => clearInterval(interval);
  }, [status]);

  return <StatusPil status={status}>{lastSeen}</StatusPil>;
}

export default Status;
