import React, { useState, useEffect, useReducer } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  useApiRequest,
  useMMAuth,
  _useMMAuthInternal,
} from '@machinemetrics/mm-react-tools';
import Machine from './Machine';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import EdgeCommander from './tools/EdgeCommander';
import { useEventStream } from '@machinemetrics/mm-react-tools';

const Heading = styled.h1`
  padding: 10px 15px;
  margin: 0;
  color: #777;
  font-weight: normal;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
`;

const Content = styled.div`
  margin-top: 75px;
`;

const Summary = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 15px;

  li {
    display: inline-block;
    padding: 5px 15px 5px 0;
    color: #777;

    a {
      text-decoration: none;
      color: #333;
    }

    button {
      border: 1px solid #ccc;
      font-size: 1em;
      color: #333;
      background-color: transparent;
      cursor: pointer;
    }
  }
`;

const MachineTable = styled.table`
  border-spacing: 0 10px;
  white-space: nowrap;
  margin: 15px;
  width: 100%;

  th {
    text-align: left;
  }

  td {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      white-space: normal; /* wrap on hover */
      overflow: visible;
      text-overflow: unset;
    }
  }
`;

const DebugTable = styled.table`
  border-spacing: 0 10px;
  white-space: normal;
  margin: 15px;
  width: 100%;

  th {
    text-align: left;
  }
`;

function App() {
  const [bindings, setBindings] = useState();
  const [edgeCommanders, setEdgeCommanders] = useState({});
  const [edgeSources, setEdgeSources] = useState();

  const { data: machinesData, loading: machinesLoading } =
    useApiRequest('/machines');
  const { urls, request } = useMMAuth();
  const { accessToken } = _useMMAuthInternal();

  const showDebug = process.env.REACT_APP_SHOW_DEBUG == 'true';

  const companyQuery = gql`
    {
      companies {
        name
        location {
          locationRef
        }
      }
    }
  `;
  const [machines, setMachines] = useState();
  const { data: companyData } = useQuery(companyQuery);

  const { data: statuses, loading: statusesLoading } = useApiRequest(
    '/machines/statuses',
    null,
    10000
  );
  const { data: agentStatuses } = useApiRequest('/agent/status', null, 10000);
  const sortedAgentStatuses = agentStatuses
    ? [...agentStatuses].sort((a, b) => a.name.localeCompare(b.name))
    : null;

  useEffect(() => {
    if (!machinesData) {
      return;
    }

    setMachines(machinesData);
  }, [machinesData]);

  const [locationRef, setLocationRef] = useState();

  useEffect(() => {
    if (companyData) {
      setLocationRef(companyData?.companies?.[0]?.location.locationRef);
    }
  }, [companyData]);

  const handleUpdateMachine = async () => {
    const response = await request(`${urls.apiUrl}/machines`);
    setMachines(response);
  };

  const [adapterVersions, dispatch] = useReducer((state, event) => {
    if (event && event.data && event.data.machineSourceRef) {
      return {
        ...state,
        [event.data.machineSourceRef]: event.data,
      };
    } else {
      return state;
    }
  }, {});

  useEventStream({
    subject: `mm.4.${locationRef}.*.*.*.4.version.0`,
    dispatch,
  });

  useEffect(() => {
    async function fetchStuff() {
      if (!machines) {
        return;
      }

      const integrationIds = _(machines)
        .map('sources')
        .flatten()
        .map('adapterBinding.adapterIntegration.id')
        .uniq()
        .flatten()
        .value();
      if (!_.includes(integrationIds, 28)) {
        integrationIds.push(28);
      }

      const results = await Promise.all(
        integrationIds.map(async (integrationId) => {
          const things = await request(
            `${urls.apiUrl}/temporary-v2/integrationtypes/${integrationId}/bindings`
          );
          return {
            id: integrationId,
            bindings: things,
          };
        })
      );

      setBindings(_(results).keyBy('id').mapValues('bindings').value());

      const edgeSources = _(machines)
        .filter('gateway.resinManaged')
        .groupBy('gateway.id')
        .mapValues((mlist) => {
          return _(mlist).map('sources').flatten().value();
        })
        .value();

      setEdgeSources(edgeSources);

      const edges = _(machines)
        .filter('gateway.resinManaged')
        .map('gateway.id')
        .uniq()
        .map((id) =>
          EdgeCommander({ edgeId: id, accessToken, apiUrl: urls.apiUrl })
        )
        .keyBy('id')
        .value();

      setEdgeCommanders(edges);
    }
    fetchStuff();
  }, [machines]);

  const [machineFilter, setMachineFilter] = useState('all');

  const handleShowAll = () => {
    setMachineFilter('all');
  };

  const handleShowReporting = () => {
    setMachineFilter('reporting');
  };

  const handleShowOffline = () => {
    setMachineFilter('offline');
  };

  const [selectedEdge, setSelectedEdge] = useState('all');
  const handleEdgeChange = (event) => {
    setSelectedEdge(event.target.value);
  };

  return (
    <div>
      <Heading>MM Connectivity Audit</Heading>
      <Content>
        {showDebug && (
          <DebugTable id="debug">
            <thead>
              <tr>
                <th>Debug Info--------------------</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Selected Edge Id: {selectedEdge}</td>
              </tr>
              <tr>
                <td>
                  Adapter Versions over NATS:
                  <pre>{JSON.stringify(adapterVersions, null, 2)}</pre>
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>End Debug Info----------------</th>
              </tr>
            </thead>
          </DebugTable>
        )}
        <Summary>
          <li>
            {companyData && <span>{companyData?.companies?.[0]?.name}</span>}
          </li>
          <li>
            <button onClick={handleShowAll}>
              {
                machines?.filter(
                  (m) => selectedEdge === 'all' || m.gateway.id === selectedEdge
                )?.length
              }{' '}
              Machines
            </button>
          </li>
          <li>
            <button onClick={handleShowReporting}>
              {
                machines
                  ?.filter((m) => statuses?.[m.id]?.status !== 'unavailable')
                  ?.filter(
                    (m) =>
                      selectedEdge === 'all' || m.gateway.id === selectedEdge
                  ).length
              }{' '}
              Reporting
            </button>
          </li>
          <li>
            <button onClick={handleShowOffline}>
              {
                machines
                  ?.filter((m) => statuses?.[m.id]?.status === 'unavailable')
                  ?.filter(
                    (m) =>
                      selectedEdge === 'all' || m.gateway.id === selectedEdge
                  ).length
              }{' '}
              Offline
            </button>
          </li>
          <li>
            <select value={selectedEdge} onChange={handleEdgeChange}>
              <option value="all">All Edges</option>
              {sortedAgentStatuses?.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} (
                  {machines?.filter((m) => m.gateway.id === agent.id).length}{' '}
                  machines)
                </option>
              ))}
            </select>
          </li>
          <li>
            <Link to="/edges">Edges Page</Link>
          </li>
          <li>
            <button onClick={handleUpdateMachine}>Refresh</button>
          </li>
        </Summary>
        <MachineTable>
          <thead>
            <tr>
              <th>Machine</th>
              <th>Edge</th>
              <th>Adapter Type</th>
              <th>IP</th>
              <th>
                Adapter Version<br></br>set in App
              </th>
              <th>
                Edge Adapter Version<br></br>Target / Current
              </th>
              <th>Tools</th>
            </tr>
          </thead>
          <tbody>
            {false &&
              companyData &&
              console.log(
                '{locationRef, Company} =>',
                '{' +
                  companyData?.companies?.[0]?.location.locationRef +
                  ', ' +
                  companyData?.companies?.[0]?.name +
                  '}'
              )}
            {false && machines && console.log('Gateway 0', machines[0].gateway)}
            {machines
              ?.filter(
                (m) =>
                  machineFilter === 'all' ||
                  (statuses?.[m.id]?.status === 'unavailable' &&
                    machineFilter === 'offline') ||
                  (machineFilter === 'reporting' &&
                    statuses?.[m.id]?.status &&
                    statuses?.[m.id]?.status !== 'unavailable')
              )
              ?.filter(
                (m) => selectedEdge === 'all' || m.gateway.id === selectedEdge
              )
              ?.map((machine) => (
                <Machine
                  machine={machine}
                  edgeCommanders={edgeCommanders}
                  edgeSources={edgeSources}
                  statuses={statuses}
                  bindings={bindings}
                  key={machine.id}
                  agents={sortedAgentStatuses}
                  onUpdateMachine={handleUpdateMachine}
                  adapterVersions={adapterVersions}
                />
              ))}
          </tbody>
        </MachineTable>
      </Content>
    </div>
  );
}

export default App;
