import { useState, useEffect } from 'react';
import { useMMAuth } from '@machinemetrics/mm-react-tools';
import _ from 'lodash';
import styled from 'styled-components';
import Constants from './Constants';

const Pil = styled.span`
  background-color: grey;
  color: #fff;
  border-radius: 0.25em;
  padding: 0.1em 0.3em;
`;

const GreenPil = styled(Pil)`
  background-color: ${Constants.GREEN};
`;

const OrangePil = styled(Pil)`
  background-color: orange;
`;

const RedPil = styled(Pil)`
  background-color: ${Constants.RED};
`;

const Button = styled.button`
  margin-right: 5px;
`;

const OrangeButton = styled(Button)`
  background-color: orange;
`;

const PingResult = ({ value }) => {
  if (value === 'success') {
    return <GreenPil>PING SUCCEEDED</GreenPil>;
  } else {
    return <RedPil>PING FAILED</RedPil>;
  }
};

function Adapter({
  source,
  edgeSources,
  bindings,
  agentId,
  machineId,
  commands,
  onUpdateSource,
}) {
  const [latestProd, setLatestProd] = useState();
  const [latestTransformProd, setLatestTransformProd] = useState();
  const [candidateTarget, setCandidateTarget] = useState();
  const { request, urls } = useMMAuth();

  const parseHost = (connectionString) => {
    // Regex pattern to match host name or IP address
    // This pattern matches a full URL, an IP address, or an IP address and port number separated by a semi-colon
    // It captures the host name
    const pattern = /^(?:[^\/]*\/\/)?([^:\/]+)/i;

    // Extract the host name using regex
    const matches = pattern.exec(connectionString);
    if (matches) {
      return matches[1];
    }

    // If no match is found, return null or throw an error, depending on your use case
    return null;
  };

  const [pingResult, setPingResult] = useState();
  const [pingResponse, setPingResponse] = useState();
  const [pinging, setPinging] = useState(false);

  const doPing = async () => {
    if (!commands) {
      return;
    }

    setPinging(true);
    const thing = await commands.ping(parseHost(source.connectionString));
    setPingResponse(thing);
    setPingResult(
      thing?.indexOf(' 0% packet loss') > 0 ? 'success' : 'failure'
    );
    setPinging(false);
  };

  const [macResult, setMacResult] = useState();
  const [macResponse, setMacResponse] = useState();
  const [macing, setMacing] = useState(false);

  const doMac = async () => {
    if (!commands) {
      return;
    }

    setMacing(true);
    const thing = await commands.mac(parseHost(source.connectionString));
    setMacResponse(thing);
    setMacResult(thing?.match(/Manufacturer: (.*)/)?.[1]);
    setMacing(false);
  };

  const updateToLatest = async () => {
    await request(`${urls.apiUrl}/temporary-v2/machines/sources/${source.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        adapterBindingId: latestProd.id,
        betaEnabled: false, // if we're allowing the user to update, we're only allowing it if they're on a non-beta build. get them off beta builds then!
        config: source.config,
        connectionString: source.connectionString,
        machineId: machineId,
        disabled: source.disabled,
        integrationType: source.adapterBinding.adapterIntegration.id,
        shdrPusherIgnore: source.shdrPusherIgnore,
        targetSourceId: source.targetSourceId,
      }),
    });

    onUpdateSource();
  };

  const convertToTransform = async () => {
    await request(`${urls.apiUrl}/temporary-v2/machines/sources/${source.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        adapterBindingId: latestTransformProd.id,
        betaEnabled: false, // if we're allowing the user to update, we're only allowing it if they're on a non-beta build. get them off beta builds then!
        config: source.config,
        connectionString: source.connectionString,
        machineId: machineId,
        disabled: source.disabled,
        integrationType: 28,
        shdrPusherIgnore: source.shdrPusherIgnore,
        targetSourceId: candidateTarget?.id,
      }),
    });

    onUpdateSource();
  };

  const findTransformCandidate = () => {
    if (source?.adapterBinding?.adapterIntegration?.id !== 19) {
      return null;
    }

    const connectionParts = source?.connectionString?.match(
      /^(localhost|supervisor):(\d+)$/
    );
    if (!connectionParts) {
      return null;
    }

    if (!agentId || !edgeSources[agentId]) {
      return null;
    }

    const port = connectionParts[2];
    if (!port) {
      return null;
    }

    const matchingSource = _.find(edgeSources[agentId], (src) => {
      return +src.adapterPort === +port;
    });
    if (!matchingSource) {
      return null;
    }

    if (
      _.includes(
        [19, 28],
        matchingSource?.adapterBinding?.adapterIntegration?.id
      )
    ) {
      return false;
    }

    return matchingSource;
  };

  useEffect(() => {
    if (!bindings) {
      return;
    }

    const binding = bindings[source?.adapterBinding?.adapterIntegration?.id];

    if (!binding) {
      return;
    }

    const tempData = _(binding)
      .filter('adapterArtifact.production')
      .filter((b) => {
        return b.adapterArtifact.adapterBuildType.platform === 'linux';
      })
      .value();
    setLatestProd(tempData.length && tempData[0]);

    const target = findTransformCandidate();
    setCandidateTarget(target);

    if (target) {
      const transformData = _(bindings[28])
        .filter('adapterArtifact.production')
        .filter((b) => {
          return b.adapterArtifact.adapterBuildType.platform === 'linux';
        })
        .value();
      setLatestTransformProd(transformData.length && transformData[0]);
    }
  }, [bindings]);

  // const [hasBadChain, setHasBadChain] = useState(false);

  // useEffect(() => {
  //   if (!source || !otherSources || otherSources.length < 2) {
  //     return;
  //   }

  //   if (parseHost(source.connectionString) !== 'supervisor' && parseHost(source.connectionString) !== 'localhost') {
  //     return;
  //   }
  //   const chainedSource = _.find(otherSources, { adapterPort: parseInt(parsePort(source.connectionString)) });
  //   console.log(source.connectionString);

  //   setHasBadChain(!chainedSource);
  // }, [source, otherSources]);

  return (
    <div>
      <Button title={pingResponse} disabled={pinging} onClick={doPing}>
        Ping
      </Button>
      <Button title={macResponse} disabled={macing} onClick={doMac}>
        MAC
      </Button>
      {source.adapterBinding?.adapterArtifact.id &&
        !source.adapterBinding?.adapterArtifact?.production && (
          <OrangePil>BETA (update in app with caution)</OrangePil>
        )}
      {/* {hasBadChain && <OrangePil>Possible Chaining Mismatch</OrangePil>} */}
      {source.adapterBinding?.adapterArtifact?.production && (
        <>
          {latestProd &&
            source.adapterBinding &&
            (latestProd?.adapterArtifact?.name ===
            source.adapterBinding?.adapterArtifact?.name ? (
              <GreenPil>
                Up to Date ({latestProd?.adapterArtifact?.name})
              </GreenPil>
            ) : (
              <OrangeButton onClick={updateToLatest}>
                Update to Latest ({latestProd?.adapterArtifact?.name})
              </OrangeButton>
            ))}
          {latestTransformProd && candidateTarget && (
            <OrangeButton onClick={convertToTransform}>
              Convert to Transform
            </OrangeButton>
          )}
        </>
      )}
      {pingResult && <PingResult value={pingResult} />}
      {macResult}
    </div>
  );
}

export default Adapter;
