import io from 'socket.io-client';

function EdgeCommander({ accessToken, edgeId, apiUrl }) {
  const responseCallbacks = {};

  let _socket;
  const getSocket = () => {
    if (!_socket) {
      _socket = new Promise((resolve) => {
        const s = io(`${apiUrl}/api/gateway/edgeManagement`, {
          autoconnect: true,
          transports: ['websocket'],
        });

        const handleConnect = () => {
          s.emit('join', {
            agentId: edgeId,
            token: accessToken,
            version: 1,
          });
        };

        s.on('connect', handleConnect);

        s.on('channel_join_success', () => {
          _socket = s;
          resolve(s);
        });

        s.on('response', (output, err) => {
          const { command, payload, requestId } = output;
          responseCallbacks[requestId] && responseCallbacks[requestId](payload);
          delete responseCallbacks[requestId];
        });
      });
    }

    return _socket;
  };

  const runCommand = async (command, args) => {
    const s = await getSocket();
    return new Promise((resolve) => {
      s.emit(
        'run_command',
        {
          commandName: command,
          commandArgs: args,
          context: 'mmedge',
        },
        (requestId) => {
          responseCallbacks[requestId] = resolve;
        }
      );
    });
  };

  return {
    id: edgeId,
    ping: async (ip) => {
      return runCommand('ping', { '--target': ip });
    },
    mac: async (ip) => {
      return runCommand('mac', { '--target': ip });
    },
  };
}

export default EdgeCommander;
