import styled from 'styled-components';
import Constants from './Constants';
import _ from 'lodash';

const Pil = styled.span`
  background-color: grey;
  color: #fff;
  border-radius: 0.25em;
  padding: 0.1em 0.3em;
`;

const BluePil = styled(Pil)`
  background-color: ${Constants.BLUE};
`;

const RedPil = styled(Pil)`
  background-color: ${Constants.RED};
`;

const LightBluePil = styled(Pil)`
  background-color: ${Constants.LIGHTBLUE};
`;

function AdapterNATSInfo({ current, desired, versionValid }) {
  const REMOVED = 'removed';
  const TO_DELETE = 'to delete';

  if (versionValid) {
    if (!current || current === '') current = REMOVED;
    if (!desired || desired === '') desired = TO_DELETE;
  }
  return (
    <div>
      {versionValid && (
        <>
          {<BluePil>{desired}</BluePil>}/
          {desired === current ||
          (desired === TO_DELETE && current === REMOVED) ? (
            <LightBluePil>{current}</LightBluePil>
          ) : (
            <RedPil>{current}</RedPil>
          )}
        </>
      )}
      {!versionValid && (
        <>
          {<Pil>'n/a'</Pil>}/{<Pil>'n/a'</Pil>}
        </>
      )}
    </div>
  );
}

export default AdapterNATSInfo;
