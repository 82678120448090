import React from 'react';
import { createRoot } from 'react-dom/client';
import { Route } from 'react-router-dom';
import { MMProvider, PrivateLayout } from '@machinemetrics/mm-react-tools';
import App from './App';
import Edges from './Edges';
import config from './config/default.json';
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <MMProvider
    releaseStage={config.releaseStage}
    domain={config.domain}
    clientId={config.clientId}
    clientSecret={config.clientSecret}
    scope={'reporting'}
  >
    <Route element={<PrivateLayout />}>
      <Route path="/" element={<App />} />
      <Route path="/edges" element={<Edges />} />
    </Route>
  </MMProvider>
);
