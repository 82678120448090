import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Adapter from './Adapter';
import AdapterNATSInfo from './AdapterNATSInfo';
import Status from './Status';
import _ from 'lodash';
import Constants from './Constants';

const Dot = styled.div`
  border-radius: 0.3em;
  width: 0.6em;
  height: 0.6em;
  background-color: grey;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.4em 0.15em 0.4em;
`;

const DisabledText = styled.div`
  font-style: italic;
  color: #777;
`;

const Text = ({ children, disabled }) => {
  return disabled ? (
    <DisabledText>{children}</DisabledText>
  ) : (
    <div>{children}</div>
  );
};

const GreenDot = styled(Dot)`
  background-color: ${Constants.GREEN};
`;

const RedDot = styled(Dot)`
  background-color: ${Constants.RED};
`;

const EdgeDot = ({ status }) => {
  if (!status) {
    return <Dot />;
  }

  return status === 'Normal' ? <GreenDot /> : <RedDot />;
};

function Machine({
  machine,
  agents,
  bindings,
  statuses,
  edgeCommanders,
  edgeSources,
  onUpdateMachine,
  adapterVersions,
}) {
  const [agent, setAgent] = useState();
  const [agentSummary, setAgentSummary] = useState();
  const [commands, setCommands] = useState();

  useEffect(() => {
    if (!machine || !agents) {
      return;
    }

    setAgent(_.find(agents, { id: machine.gateway.id }));
  }, [agents, machine]);

  useEffect(() => {
    if (!agent || !edgeCommanders) {
      return;
    }

    setCommands(edgeCommanders[agent.id]);
    setAgentSummary(`Buffer: ${agent.pusher.reported.bufferedRecords}
Activation: ${agent.activation.reported.connected === 'true' ? 'Good' : 'Bad'}
Supervisor: ${agent.supervisor.reported.connected === 'true' ? 'Good' : 'Bad'}
Pusher: ${agent.pusher.reported.connected === 'true' ? 'Good' : 'Bad'}`);
  }, [agent, edgeCommanders]);

  return (
    <tr>
      <td>
        <a
          href={`https://app.machinemetrics.com/app/machines/${machine.id}/edit`}
          target="_blank"
        >
          {machine.name}
        </a>
        <Status status={statuses && statuses[machine.id]} />
      </td>
      <td>
        <a
          title={agentSummary}
          target="_blank"
          href={`https://dashboard.balena-cloud.com/devices/${agent?.resinUUID}`}
        >
          {agent?.name}
        </a>
        <EdgeDot status={agent?.gateway.common} />
      </td>
      <td>
        {machine.sources.map((source) => (
          <Text key={source.id} disabled={source.disabled}>
            {source.adapterBinding.adapterIntegration.displayName}
          </Text>
        ))}
      </td>
      <td>
        {machine.sources.map((source) => (
          <Text key={source.id} disabled={source.disabled}>
            {source.connectionString?.toLowerCase() === 'usb'
              ? ' via USB'
              : source.connectionString}
          </Text>
        ))}
      </td>
      <td>
        {machine.sources.map((source) => (
          <Text key={source.id} disabled={source.disabled}>
            {source.adapterBinding.adapterArtifact.name}
          </Text>
        ))}
      </td>
      <td>
        {machine.sources.map((source) => (
          <AdapterNATSInfo
            key={source.id}
            current={adapterVersions[source.id]?.value}
            desired={adapterVersions[source.id]?.desired}
            versionValid={
              adapterVersions &&
              !_.isEmpty(adapterVersions) &&
              source &&
              adapterVersions[source?.id]
            }
          />
        ))}
      </td>
      <td>
        {machine.sources.map((source) => (
          <Adapter
            onUpdateSource={onUpdateMachine}
            agentId={machine?.gateway?.id}
            machineId={machine.id}
            edgeSources={edgeSources}
            commands={commands}
            bindings={bindings}
            source={source}
            key={source.id}
          />
        ))}
      </td>
    </tr>
  );
}

export default Machine;
