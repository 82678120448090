import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  useApiRequest,
  useMMAuth,
  _useMMAuthInternal,
} from '@machinemetrics/mm-react-tools';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import EdgeCommander from './tools/EdgeCommander';
import Constants from './Constants';

const Dot = styled.div`
  border-radius: 0.3em;
  width: 0.6em;
  height: 0.6em;
  background-color: grey;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.4em 0.15em 0.4em;
`;

const Heading = styled.h1`
  padding: 10px 15px;
  margin: 0;
  color: #777;
  font-weight: normal;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
`;

const Content = styled.div`
  margin-top: 75px;
`;

const Summary = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 15px;

  li {
    display: inline-block;
    padding: 5px 15px 5px 0;
    color: #777;

    a {
      text-decoration: none;
      color: #333;
    }

    button {
      border: none;
      font-size: 1em;
      color: #333;
      background-color: transparent;
      cursor: pointer;
    }
  }
`;

const GreenDot = styled(Dot)`
  background-color: ${Constants.GREEN};
`;

const RedDot = styled(Dot)`
  background-color: ${Constants.RED};
`;

const MachineTable = styled.table`
  border-spacing: 0 10px;
  white-space: nowrap;
  margin: 15px;
  width: 100%;

  th {
    text-align: left;
  }
`;

const DebugTable = styled.table`
  border-spacing: 0 10px;
  white-space: nowrap;
  margin: 15px;
  width: 100%;

  th {
    text-align: left;
  }
`;

const formatSince = (timestamp) => {
  const isoDate = DateTime.fromISO(timestamp);
  const date = isoDate.isValid ? isoDate : DateTime.fromSQL(timestamp);
  const diff = date.diffNow().milliseconds;
  return humanizeDuration(diff, { largest: 1, round: true });
};

const EdgeDot = ({ status }) => {
  if (!status) {
    return <Dot />;
  }

  return status === 'Normal' ? <GreenDot /> : <RedDot />;
};

function Edges() {
  const [bindings, setBindings] = useState();
  const [edgeCommanders, setEdgeCommanders] = useState({});

  const { data: machinesData, loading: machinesLoading } =
    useApiRequest('/machines');
  const { urls, request } = useMMAuth();
  const { accessToken } = _useMMAuthInternal();

  const companyQuery = gql`
    {
      companies {
        name
      }
    }
  `;

  const [machines, setMachines] = useState();

  useEffect(() => {
    if (!machinesData) {
      return;
    }

    setMachines(machinesData);
  }, [machinesData]);

  const { data: companyData } = useQuery(companyQuery);

  const { data: statuses, loading: statusesLoading } = useApiRequest(
    '/machines/statuses',
    null,
    10000
  );
  const { data: agentStatuses } = useApiRequest('/agent/status', null, 10000);
  const sortedAgentStatuses = agentStatuses
    ? [...agentStatuses].sort((a, b) => a.name.localeCompare(b.name))
    : null;

  const handleUpdateMachine = async () => {
    const response = await request(`${urls.apiUrl}/machines`);
    setMachines(response);
  };

  useEffect(() => {
    async function fetchStuff() {
      if (!machines) {
        return;
      }

      const integrationIds = _(machines)
        .map('sources')
        .flatten()
        .map('adapterBinding.adapterIntegration.id')
        .uniq()
        .flatten()
        .value();
      const results = await Promise.all(
        integrationIds.map(async (integrationId) => {
          const things = await request(
            `${urls.apiUrl}/temporary-v2/integrationtypes/${integrationId}/bindings`
          );
          return {
            id: integrationId,
            bindings: things,
          };
        })
      );

      setBindings(_(results).keyBy('id').mapValues('bindings').value());

      const edges = _(machines)
        .filter('gateway.resinManaged')
        .map('gateway.id')
        .uniq()
        .map((id) =>
          EdgeCommander({ edgeId: id, accessToken, apiUrl: urls.apiUrl })
        )
        .keyBy('id')
        .value();

      setEdgeCommanders(edges);
    }
    fetchStuff();
  }, [machines]);

  const [machineFilter, setMachineFilter] = useState('all');

  const handleShowAll = () => {
    setMachineFilter('all');
  };

  const handleShowReporting = () => {
    setMachineFilter('reporting');
  };

  const handleShowOffline = () => {
    setMachineFilter('offline');
  };

  const [selectedEdge, setSelectedEdge] = useState('all');
  const handleEdgeChange = (event) => {
    setSelectedEdge(event.target.value);
  };

  return (
    <div>
      <Heading>MM Connectivity Audit</Heading>
      <Content>
        <Summary>
          <li>
            {companyData && <span>{companyData?.companies?.[0]?.name}</span>}
          </li>
          <li>
            <button onClick={handleShowAll}>
              {
                machines?.filter(
                  (m) => selectedEdge === 'all' || m.gateway.id === selectedEdge
                )?.length
              }{' '}
              Machines
            </button>
          </li>
          <li>
            <button onClick={handleShowReporting}>
              {
                machines
                  ?.filter((m) => statuses?.[m.id]?.status !== 'unavailable')
                  ?.filter(
                    (m) =>
                      selectedEdge === 'all' || m.gateway.id === selectedEdge
                  ).length
              }{' '}
              Reporting
            </button>
          </li>
          <li>
            <button onClick={handleShowOffline}>
              {
                machines
                  ?.filter((m) => statuses?.[m.id]?.status === 'unavailable')
                  ?.filter(
                    (m) =>
                      selectedEdge === 'all' || m.gateway.id === selectedEdge
                  ).length
              }{' '}
              Offline
            </button>
          </li>
          <li>
            <select value={selectedEdge} onChange={handleEdgeChange}>
              <option value="all">All Edges</option>
              {sortedAgentStatuses?.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} (
                  {machines?.filter((m) => m.gateway.id === agent.id).length}{' '}
                  machines)
                </option>
              ))}
            </select>
          </li>
          <li>
            <Link to="/">Machines Page</Link>
          </li>
        </Summary>
        {process.env.REACT_APP_SHOW_DEBUG && (
          <DebugTable>
            <thead>
              <tr>
                <th>Debug Info</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Selected Edge Id: {selectedEdge}</td>
              </tr>
            </tbody>
          </DebugTable>
        )}
        <MachineTable>
          <thead>
            <tr>
              <th>Edge</th>
              <th>Machines</th>
              <th>Buffered Records</th>
              <th>Last Seen</th>
              <th>Activation</th>
              <th>Pusher</th>
              <th>Supervisor</th>
            </tr>
          </thead>
          <tbody>
            {sortedAgentStatuses?.map((a) => (
              <tr key={a.id}>
                <td>
                  <a
                    target="_blank"
                    href={`https://dashboard.balena-cloud.com/devices/${a.resinUUID}`}
                  >
                    {a.name}
                    <EdgeDot status={a?.gateway.common} />
                  </a>
                </td>
                <td>{machines?.filter((m) => m.gateway.id === a.id).length}</td>
                <td>{a.pusher.reported.bufferedRecords}</td>
                <td>{formatSince(a.pusher.reported.heartbeat)} ago</td>
                <td title={a.activation.reported.heartbeat}>
                  {a.activation.reported.connected === 'true' ? 'Good' : 'Bad'}
                </td>
                <td title={a.pusher.reported.heartbeat}>
                  {a.pusher.reported.connected === 'true' ? 'Good' : 'Bad'}
                </td>
                <td title={a.supervisor.reported.heartbeat}>
                  {a.supervisor.reported.connected === 'true' ? 'Good' : 'Bad'}
                </td>
              </tr>
            ))}
          </tbody>
        </MachineTable>
      </Content>
    </div>
  );
}

export default Edges;
